import {
  AllTreatmentPrograms,
  TreatmentProgram,
  TreatmentProgramConfig,
} from 'global.constants'

import { trackEligibilityCheckCall } from '~services/analyticsService/analytics.service'
import { apiClient } from '~services/apiClient'

export interface ELID {
  companyId?: number
  slugCompanyId?: number
  dateOfBirth: string
  firstName?: string
  lastName: string
  province?: string
  postalCode: string
  accessCode?: string
}

export interface EligibilityResponse {
  companyId?: number
  success: boolean
  treatmentPrograms?: Partial<
    Record<
      TreatmentProgram,
      {
        eligible: boolean
        accessCode?: string
      }
    >
  >
}

export interface EligibilityResult {
  companyId?: number
  treatmentAccessCode: TreatmentAccessCode
  treatmentPrograms: TreatmentProgramConfig
}

/**
 * Fetch a user's record from the general eligibility endpoint
 */
export default async function fetchCompanyEligibility(
  elid: ELID
): Promise<EligibilityResult> {
  trackEligibilityCheckCall('v1')

  const url = '/b2b-user-onboarding/check-eligibility'
  const { data } = await apiClient.put<EligibilityResponse>(url, {
    DOB: elid.dateOfBirth,
    firstName: elid.firstName,
    lastName: elid.lastName,
    ZIP: elid.postalCode,
    ...(!!elid.companyId &&
      // for partnership (identified by slugCompanyId),
      // id of employer company should not be sent when access code is provided
      // because the access code is unique to the partnership
      // and should override wrong company selection
      (!elid.slugCompanyId || !elid.accessCode) && {
        companyId: elid.companyId.toString(),
      }),
    ...(!!elid.slugCompanyId && { slugCompanyId: elid.slugCompanyId }),
    ...(!!elid.province && { state: elid.province }),
    ...(!!elid.accessCode && { accessCode: elid.accessCode }),
  })

  // TODO: Update if ESI adds cannabis/stimulants: https://quitgenius.atlassian.net/browse/COPS-1307?focusedCommentId=56610
  const treatmentAccessCode: TreatmentAccessCode = {
    alcohol: '',
    cigarettes: '',
    eCigarettes: '',
    opioids: '',
  }

  const treatmentPrograms = Object.fromEntries(
    AllTreatmentPrograms.map((p) => [p, false])
  ) as TreatmentProgramConfig

  if (data.treatmentPrograms) {
    for (const [treatmentProgram, { eligible, accessCode }] of Object.entries(
      data.treatmentPrograms
    )) {
      treatmentPrograms[treatmentProgram as keyof TreatmentProgramConfig] =
        eligible
      if (accessCode) {
        treatmentAccessCode[treatmentProgram as keyof TreatmentAccessCode] =
          accessCode
      }
    }
  }
  return {
    companyId: data.companyId,
    treatmentPrograms,
    treatmentAccessCode,
  }
}
