import { StatusType, datadogLogs } from '@datadog/browser-logs'
import { FlowVersion } from 'flow-version'
import {
  AuthType,
  TobaccoSubAddictions,
  TreatmentProgram,
} from 'global.constants'
import { ReplaySubject } from 'rxjs'

import { AddictionSelectionParentAddiction } from '~components/AddictionSelectorBasePage/AddictionSelectorBasePage'
import { StateAvailability } from '~constants/types'
import { SignupContext } from '~services/signupMachine/types/signupContext'
import { OnboardingType } from '~services/signupMachine/types/user'
import {
  getStateAvailabilityVariation,
  isParentAddiction,
  isTobaccoSubAddiction,
} from '~utils/helpers'

export enum AnalyticsTopic {
  PageView = 'pageView',
  IdentifyClient = 'identify.client',
  IdentifyFlowVersion = 'identify.flowVersion',
  IdentifyAddiction = 'identify.addiction',
  IdentifyPolyAddictions = 'identify.polyAddictions',
  IdentifyPolyaddictionEnabled = 'identify.polyaddictionEnabled',
  IdentifyStateUnavailability = 'identify.stateUnavailability',
  IdentifyMinorRegistrationStatus = 'identify.minorRegistrationStatus',
  IdentifyMember = 'identify.member',
  IdentifyOnboardingSelection = 'identify.onboardingSelection',
  IdentifyUTMParams = 'identify.UTMParams',
  DownloadApp = 'downloadApp',
  DownloadLinkVisit = 'downloadLinkVisit',
  ApiRequestStart = 'apiRequest.start',
  ApiRequestSuccess = 'apiRequest.success',
  ApiRequestError = 'apiRequest.error',
  EmailFilledIn = 'emailFilledIn',
  RegistrationComplete = 'registrationComplete',
  FieldBlurred = 'fieldBlurred',
  ContinueClicked = 'continueClicked',
  HealthieSchedulingEvent = 'healthieSchedulingEvent',
  CallBooked = 'callBooked',
  SubstanceSelected = 'substanceSelected',
  SubstanceDeselected = 'substanceDeselected',
  NicotineTypeSelected = 'NicotineTypeSelected',
  NicotineTypeDeselected = 'NicotineTypeDeselected',
  ConnectivityChange = 'connectivityChange',
  WindowNavigatedRefreshedClosed = 'windowNavigatedRefreshedClosed',
  ClickDisclosureOfDataConsentFileLink = 'clickDisclosureOfDataConsentFileLink',
  LogMessage = 'logMessage',
  PageValidation = 'pageValidation',
  RejoinPageView = 'rejoinPageView',
  SecondaryCTAClicked = 'secondaryCTAClicked',
  LPConversionIdentified = 'LPConversionIdentified',
  LPConversionSuccess = 'LPConversionSuccess',
  LPConversionError = 'LPConversionError',
  AuthTypeSet = 'authTypeSet',
  PersonifyRedirectDecision = 'personifyRedirectDecision',
  PersonifyRedirect = 'personifyRedirect',
  HDHPNoticeRequirement = 'HDHPNoticeRequirement',
  HDHPNoticeChoice = 'HDHPNoticeChoice',
  HDHPNoticeDialogInteraction = 'HDHPNoticeDialogInteraction',
  EligibilityCheckCall = 'eligibilityCheckCall',
}

const analyticsBus = new ReplaySubject<
  | PageView
  | TrackCompany
  | TrackFlowVersion
  | TrackAddiction
  | TrackPolyAddictions
  | TrackPolyaddictionEnabled
  | TrackStateAvailability
  | TrackMinorRegistrationStatus
  | TrackMemberId
  | TrackDownloadAppClick
  | TrackDownloadLinkVisit
  | TrackApiRequestStart
  | TrackApiRequestSuccess
  | TrackApiRequestError
  | TrackEmailFilledIn
  | TrackRegistrationComplete
  | TrackFieldBlurred
  | TrackContinueClicked
  | TrackHealthieSchedulingEvent
  | TrackCallBooked
  | TrackSubstanceSelected
  | TrackSubstanceDeselected
  | TrackNicotineTypeSelected
  | TrackNicotineTypeDeselected
  | TrackConnectivity
  | TrackWindowNavigatedRefreshedClosed
  | TrackClickDisclosureOfDataConsent
  | TrackUTMParams
  | LogMessage
  | RejoinPageView
  | TrackPageValidation
  | TrackSecondaryCTAClicked
  | TrackOnboardingSelection
  | TrackLPConversionIdentified
  | TrackLPConversionSuccess
  | TrackLPConversionError
  | TrackAuthTypeSet
  | TrackPersonifyRedirectDecision
  | TrackPersonifyRedirect
  | TrackHDHPNoticeRequirement
  | TrackHDHPNoticeChoice
  | TrackHDHPNoticeDialogInteraction
  | TrackEligibilityCheckCall
>()

export default analyticsBus

export function trackPageView(
  pageName: PageView['pageName'],
  details?: object
): void {
  analyticsBus.next({ topic: AnalyticsTopic.PageView, pageName, details })
}

export function trackPageValidation(valid: boolean, error?: string): void {
  analyticsBus.next({ topic: AnalyticsTopic.PageValidation, valid, error })
}

export function trackRejoinPageView(
  pageName: RejoinPageView['pageName'],
  details?: object
): void {
  analyticsBus.next({ topic: AnalyticsTopic.RejoinPageView, pageName, details })
}

export function trackSecondaryCTAClicked(
  pageName: RejoinPageView['pageName']
): void {
  analyticsBus.next({ topic: AnalyticsTopic.SecondaryCTAClicked, pageName })
}

export function trackFieldBlurred(
  pageName: JointPageName,
  fieldName: string,
  validationError?: string
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.FieldBlurred,
    pageName,
    fieldName,
    validationError,
  })
}

export function trackContinueClicked(
  pageName: JointPageName,
  errorKeys: string[]
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.ContinueClicked,
    pageName,
    validationErrors: errorKeys,
  })
}

export function trackHealthieSchedulingEvent(event: string): void {
  analyticsBus.next({ topic: AnalyticsTopic.HealthieSchedulingEvent, event })
}

export function trackCallBooked(details?: TrackCallBooked['details']): void {
  analyticsBus.next({ topic: AnalyticsTopic.CallBooked, details: details })
}

export function trackAddictionChange(
  substance: string,
  isChecked: boolean
): void {
  if (isParentAddiction(substance)) {
    isChecked
      ? trackSubstanceSelected(substance)
      : trackSubstanceDeselected(substance)
  } else if (isTobaccoSubAddiction(substance)) {
    isChecked
      ? trackNicotineTypeSelected(substance)
      : TrackNicotineTypeDeselected(substance)
  }
}

function trackSubstanceSelected(
  substance: AddictionSelectionParentAddiction
): void {
  analyticsBus.next({ topic: AnalyticsTopic.SubstanceSelected, substance })
}
function trackSubstanceDeselected(
  substance: AddictionSelectionParentAddiction
): void {
  analyticsBus.next({ topic: AnalyticsTopic.SubstanceDeselected, substance })
}
function trackNicotineTypeSelected(nicotineType: TobaccoSubAddictions): void {
  analyticsBus.next({
    topic: AnalyticsTopic.NicotineTypeSelected,
    nicotineType,
  })
}
function TrackNicotineTypeDeselected(nicotineType: TobaccoSubAddictions): void {
  analyticsBus.next({
    topic: AnalyticsTopic.NicotineTypeDeselected,
    nicotineType,
  })
}

export function trackCompany(
  clientId: TrackCompany['clientId'],
  clientSlug: TrackCompany['clientSlug']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.IdentifyClient,
    clientId,
    clientSlug,
  })
}

export function trackFlowVersion(payload: TrackFlowVersion['payload']): void {
  analyticsBus.next({ topic: AnalyticsTopic.IdentifyFlowVersion, payload })
}

export function trackAddiction(addiction: TrackAddiction['addiction']): void {
  analyticsBus.next({ topic: AnalyticsTopic.IdentifyAddiction, addiction })
}

export function trackOnboardingSelection(
  onboardingType?: OnboardingType
): void {
  let onboardingSelection: TrackOnboardingSelection['onboardingSelection']

  switch (onboardingType) {
    case OnboardingType.DigitalOnboarding:
      onboardingSelection = 'digital'
      break
    case OnboardingType.VirtualAppointmentOnboarding:
      onboardingSelection = 'call'
      break
    default:
      onboardingSelection = 'default'
  }

  analyticsBus.next({
    topic: AnalyticsTopic.IdentifyOnboardingSelection,
    onboardingSelection,
  })
}

export function trackPolyAddictions(
  addictions: TrackPolyAddictions['addictions']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.IdentifyPolyAddictions,
    addictions,
    addictionsCount: addictions?.length,
  })
}

export function trackPolyaddictionEnabled(
  polyaddictionEnabled: TrackPolyaddictionEnabled['polyaddictionEnabled']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.IdentifyPolyaddictionEnabled,
    polyaddictionEnabled,
  })
}

export function trackStateAvailability(
  stateUnavailability?: TrackStateAvailability['stateUnavailability']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.IdentifyStateUnavailability,
    stateUnavailability,
    stateAvailabilityStatus: getStateAvailabilityVariation(stateUnavailability),
  })
}

export function trackMinorRegistrationStatus(isMinor: boolean): void {
  analyticsBus.next({
    topic: AnalyticsTopic.IdentifyMinorRegistrationStatus,
    isMinor,
  })
}

export function trackMemberId(memberId: TrackMemberId['memberId']): void {
  analyticsBus.next({ topic: AnalyticsTopic.IdentifyMember, memberId })
}

export function trackDownloadAppClick(
  platform: TrackDownloadAppClick['platform']
): void {
  analyticsBus.next({ topic: AnalyticsTopic.DownloadApp, platform })
}

export function trackDownloadLinkVisit(
  properties: TrackDownloadLinkVisit['properties']
): void {
  analyticsBus.next({ topic: AnalyticsTopic.DownloadLinkVisit, properties })
}

export function trackApiRequestStart(
  apiRequestStart: TrackApiRequestStart['payload']
) {
  analyticsBus.next({
    topic: AnalyticsTopic.ApiRequestStart,
    payload: apiRequestStart,
  })
}

export function trackApiRequestSuccess(
  apiRequestSuccess: TrackApiRequestSuccess['payload']
) {
  analyticsBus.next({
    topic: AnalyticsTopic.ApiRequestSuccess,
    payload: apiRequestSuccess,
  })
}

export function trackApiRequestError(
  apiRequestError: TrackApiRequestError['payload']
) {
  analyticsBus.next({
    topic: AnalyticsTopic.ApiRequestError,
    payload: apiRequestError,
  })
}

export function trackEmailFilledIn(email: TrackEmailFilledIn['email']): void {
  analyticsBus.next({ topic: AnalyticsTopic.EmailFilledIn, email })
}

export function trackRegistrationComplete(): void {
  analyticsBus.next({ topic: AnalyticsTopic.RegistrationComplete })
}

export function trackConnectivity(isOnline: boolean): void {
  analyticsBus.next({
    topic: AnalyticsTopic.ConnectivityChange,
    state: isOnline ? 'Online' : 'Offline',
    timestamp: Date.now(),
  })
}

export function trackWindowNavigatedRefreshedClosed(): void {
  analyticsBus.next({ topic: AnalyticsTopic.WindowNavigatedRefreshedClosed })
}

export function trackClickDisclosureOfDataConsentFileLink(): void {
  analyticsBus.next({
    topic: AnalyticsTopic.ClickDisclosureOfDataConsentFileLink,
  })
}

export function trackUTMParams(): void {
  analyticsBus.next({
    payload: getUTMParams() || {},
    topic: AnalyticsTopic.IdentifyUTMParams,
  })
}

export function trackLPConversionIdentified(
  properties: TrackLPConversionIdentified['properties']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.LPConversionIdentified,
    properties,
  })
}

export function trackLPConversionSuccess(): void {
  analyticsBus.next({ topic: AnalyticsTopic.LPConversionSuccess })
}

export function trackLPConversionError(error?: Error | string): void {
  analyticsBus.next({
    topic: AnalyticsTopic.LPConversionError,
    error: error?.toString(),
  })
}

export function trackAuthTypeSet(type: TrackAuthTypeSet['type']): void {
  analyticsBus.next({ topic: AnalyticsTopic.AuthTypeSet, type })
}

export function trackPersonifyRedirectDecision(
  attributes: TrackPersonifyRedirectDecision['attributes']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.PersonifyRedirectDecision,
    attributes,
  })
}

export function trackPersonifyRedirect(): void {
  analyticsBus.next({
    topic: AnalyticsTopic.PersonifyRedirect,
  })
}

export function trackHDHPNoticeRequirement(
  required: TrackHDHPNoticeRequirement['required']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.HDHPNoticeRequirement,
    required,
  })
}

export function trackHDHPNoticeChoice(
  choice: TrackHDHPNoticeChoice['choice']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.HDHPNoticeChoice,
    choice: choice,
  })
}

export function trackHDHPNoticeDialogInteraction(
  interaction: TrackHDHPNoticeDialogInteraction['interaction']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.HDHPNoticeDialogInteraction,
    interaction: interaction,
  })
}

export function trackEligibilityCheckCall(
  version: TrackEligibilityCheckCall['version']
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.EligibilityCheckCall,
    version,
  })
}

export function logMessage(
  ...[message, messageContext, status, error]: Parameters<
    typeof datadogLogs.logger.log
  >
): void {
  analyticsBus.next({
    topic: AnalyticsTopic.LogMessage,
    message,
    messageContext,
    status,
    error,
  })
}

export interface PageView {
  topic: AnalyticsTopic.PageView
  pageName:
    | 'accessCode'
    | 'addictionSelector'
    | 'beInTouch'
    | 'careCoordinatorCall'
    | 'companySelector'
    | 'downloadTheApp'
    | 'eligibilityStep'
    | 'noStateEligibility'
    | 'insurancePlanDetails'
    | 'internalError'
    | 'signUpStep2'
    | 'starterCall'
    | 'esiCarrierSelector'
    | 'lighterAccountCreation'
    | 'lighterOTPAccountCreation'
    | 'lighterEligibilityName'
    | 'lighterEligibilityBirth'
    | 'substanceSelectionPage'
    | 'lighterBookCall'
    | 'lighterEligibilityAddress'
    | 'lighterDownloadApp'
    | 'lighterPhoneNumber'
    | 'lighterEmailAddress'
    | 'lighterStateUnavailable'
    | 'lighterWellBeInTouch'
    | 'lighterSuccessfullyRegistered'
    | 'successfullyRegistered'
    | 'callChoiceDecision'
    | 'OTPCode'
    | 'hdhpNotice'
  details?: object
}

export interface RejoinPageView {
  topic: AnalyticsTopic.RejoinPageView
  pageName:
    | 'accountLogin'
    | 'accountPasswordLogin'
    | 'resetPasswordRequest'
    | 'emailConfirmation'
    | 'updatePassword'
    | 'resetPasswordCodeExpired'
    | 'passwordSuccessfullyUpdated'
  details?: object
}

export type JointPageName = PageView['pageName'] | RejoinPageView['pageName']

export const pageNameMap: Record<PageView['pageName'], string> = {
  accessCode: 'accessCode',
  addictionSelector: 'addictionSelector',
  beInTouch: 'beInTouch',
  careCoordinatorCall: 'careCoordinatorCall',
  companySelector: 'companySelector',
  downloadTheApp: 'downloadTheApp',
  eligibilityStep: 'eligibilityCheck',
  insurancePlanDetails: 'insurancePlanDetails',
  internalError: 'internalError',
  noStateEligibility: 'noStateEligibility',
  signUpStep2: 'additionalUserDetails',
  starterCall: 'starterCall',
  esiCarrierSelector: 'esiCarrierSelector',
  lighterAccountCreation: 'lighterAccountCreation',
  lighterOTPAccountCreation: 'lighterOTPAccountCreation',
  lighterEligibilityName: 'lighterEligibilityName',
  lighterEligibilityBirth: 'lighterEligibilityBirth',
  lighterBookCall: 'lighterBookCall',
  substanceSelectionPage: 'SubstanceSelectionPage',
  lighterEligibilityAddress: 'lighterEligibilityAddress',
  lighterDownloadApp: 'lighterDownloadApp',
  lighterPhoneNumber: 'lighterPhoneNumber',
  lighterEmailAddress: 'lighterEmailAddress',
  lighterStateUnavailable: 'lighterStateUnavailable',
  lighterWellBeInTouch: 'lighterWellBeInTouch',
  lighterSuccessfullyRegistered: 'lighterSuccessfullyRegistered',
  successfullyRegistered: 'successfullyRegistered',
  callChoiceDecision: 'callChoiceDecision',
  OTPCode: 'OTPCode',
  hdhpNotice: 'hdhpNotice',
}

export const rejoinPageNameMap: Record<RejoinPageView['pageName'], string> = {
  accountLogin: 'accountLogin',
  accountPasswordLogin: 'accountPasswordLogin',
  resetPasswordRequest: 'resetPasswordRequest',
  emailConfirmation: 'emailConfirmation',
  updatePassword: 'updatePassword',
  resetPasswordCodeExpired: 'resetPasswordCodeExpired',
  passwordSuccessfullyUpdated: 'passwordSuccessfullyUpdated',
}

export interface TrackPageValidation {
  topic: AnalyticsTopic.PageValidation
  valid: boolean
  error?: string
}

export interface TrackSecondaryCTAClicked {
  topic: AnalyticsTopic.SecondaryCTAClicked
  pageName: RejoinPageView['pageName']
}

export interface TrackCompany {
  clientId: number
  clientSlug: string
  topic: AnalyticsTopic.IdentifyClient
}

export interface TrackFlowVersion {
  payload: {
    flowVersion: FlowVersion
    log?: string
  }
  topic: AnalyticsTopic.IdentifyFlowVersion
}

export interface TrackAddiction {
  addiction: TreatmentProgram
  topic: AnalyticsTopic.IdentifyAddiction
}

export interface TrackOnboardingSelection {
  topic: AnalyticsTopic.IdentifyOnboardingSelection
  onboardingSelection: 'digital' | 'call' | 'default'
}

export interface TrackPolyAddictions {
  addictions: TreatmentProgram[]
  addictionsCount: number
  topic: AnalyticsTopic.IdentifyPolyAddictions
}

export interface TrackPolyaddictionEnabled {
  polyaddictionEnabled: boolean
  topic: AnalyticsTopic.IdentifyPolyaddictionEnabled
}

export interface TrackStateAvailability {
  stateUnavailability?: SignupContext['unavailability']['state']
  stateAvailabilityStatus: StateAvailability
  topic: AnalyticsTopic.IdentifyStateUnavailability
}

export interface TrackMinorRegistrationStatus {
  isMinor: boolean
  topic: AnalyticsTopic.IdentifyMinorRegistrationStatus
}

export interface TrackMemberId {
  memberId: string
  topic: AnalyticsTopic.IdentifyMember
}

export interface TrackDownloadAppClick {
  platform: 'iOS' | 'Android'
  topic: AnalyticsTopic.DownloadApp
}

export interface TrackDownloadLinkVisit {
  topic: AnalyticsTopic.DownloadLinkVisit
  properties: { medium: string }
}

export interface TrackApiRequestStart {
  topic: AnalyticsTopic.ApiRequestStart
  payload: {
    endpointUrl?: string
    method?: string
    name?: string
  }
}

export interface TrackApiRequestSuccess {
  topic: AnalyticsTopic.ApiRequestSuccess
  payload: {
    endpointUrl?: string
    method?: string
    name?: string
    status?: number
  }
}

export interface TrackApiRequestError {
  topic: AnalyticsTopic.ApiRequestError
  payload: {
    endpointUrl?: string
    error: {
      message: string
      status?: number
    }
    method?: string
    name?: string
  }
}

export interface TrackEmailFilledIn {
  topic: AnalyticsTopic.EmailFilledIn
  email: string
}

export interface TrackRegistrationComplete {
  topic: AnalyticsTopic.RegistrationComplete
}

export interface TrackFieldBlurred {
  topic: AnalyticsTopic.FieldBlurred
  pageName: JointPageName
  fieldName: string
  validationError?: string
}

export interface TrackContinueClicked {
  topic: AnalyticsTopic.ContinueClicked
  pageName: JointPageName
  validationErrors: string[]
}

export interface TrackHealthieSchedulingEvent {
  topic: AnalyticsTopic.HealthieSchedulingEvent
  event: string
}

export interface TrackCallBooked {
  topic: AnalyticsTopic.CallBooked
  details?: {
    careCoordinatorCall: boolean
  }
}

export interface TrackSubstanceSelected {
  topic: AnalyticsTopic.SubstanceSelected
  substance: AddictionSelectionParentAddiction
}

export interface TrackSubstanceDeselected {
  topic: AnalyticsTopic.SubstanceDeselected
  substance: AddictionSelectionParentAddiction
}

export interface TrackNicotineTypeSelected {
  topic: AnalyticsTopic.NicotineTypeSelected
  nicotineType: TobaccoSubAddictions
}

export interface TrackNicotineTypeDeselected {
  topic: AnalyticsTopic.NicotineTypeDeselected
  nicotineType: TobaccoSubAddictions
}

export interface TrackConnectivity {
  topic: AnalyticsTopic.ConnectivityChange
  state: 'Offline' | 'Online'
  timestamp: number
}

export interface TrackWindowNavigatedRefreshedClosed {
  topic: AnalyticsTopic.WindowNavigatedRefreshedClosed
}

export interface TrackClickDisclosureOfDataConsent {
  topic: AnalyticsTopic.ClickDisclosureOfDataConsentFileLink
}

export interface TrackUTMParams {
  topic: AnalyticsTopic.IdentifyUTMParams
  payload: Record<string, string>
}

export interface TrackLPConversionIdentified {
  topic: AnalyticsTopic.LPConversionIdentified
  properties: {
    LPBuilderSessionId?: string | null
    LPBuilderVisitorId?: string | null
  }
}

export interface TrackLPConversionSuccess {
  topic: AnalyticsTopic.LPConversionSuccess
}

export interface TrackLPConversionError {
  topic: AnalyticsTopic.LPConversionError
  error?: string
}

export interface TrackAuthTypeSet {
  topic: AnalyticsTopic.AuthTypeSet
  type: {
    label: string
    value: AuthType
  }
}

export interface TrackPersonifyRedirectDecision {
  topic: AnalyticsTopic.PersonifyRedirectDecision
  attributes: {
    authenticated?: boolean
    error?: string | null
    errorDescription?: string | null
    redirect: boolean
    isPersonify: boolean
  }
}

export interface TrackPersonifyRedirect {
  topic: AnalyticsTopic.PersonifyRedirect
}

export interface TrackHDHPNoticeRequirement {
  topic: AnalyticsTopic.HDHPNoticeRequirement
  required: boolean
}

export interface TrackHDHPNoticeChoice {
  topic: AnalyticsTopic.HDHPNoticeChoice
  choice: 'book_call' | 'skip_call'
}

export interface TrackHDHPNoticeDialogInteraction {
  topic: AnalyticsTopic.HDHPNoticeDialogInteraction
  interaction: 'open' | 'close'
}

export interface TrackEligibilityCheckCall {
  topic: AnalyticsTopic.EligibilityCheckCall
  version: 'v1' | 'v2'
}

export interface LogMessage {
  topic: AnalyticsTopic.LogMessage
  message: string
  messageContext?: object | undefined
  status?: StatusType | undefined
  error?: Error | undefined
}

export function getUTMParams(): Record<string, string> | undefined {
  const queryParams = new URLSearchParams(window.location.search)
  if (!queryParams) {
    return
  }

  return Array.from(queryParams.entries()).reduce(
    (acc, [key, value]) => {
      if (key.includes('utm')) {
        acc[key] = value
      }
      return acc
    },
    {} as Record<string, string>
  )
}
