import { apiClient } from '~services/apiClient'
import { SignupMachineError } from '~services/signupMachine/signup-machine-error'

import { Company } from './schema'

interface CompanyService {
  getById(id: number): Promise<Company | undefined>
}

export type BEClientConfig = Omit<
  Company,
  'enabled' | 'gbEthnicity' | 'requireSouthAfricaIDNumber'
> & {
  additionalEligibility: {
    ethnicity?: boolean
    requireSouthAfricaIDNumber?: boolean
  }
}

const companyService: CompanyService = {
  async getById(id) {
    let client: BEClientConfig | undefined
    try {
      const { data } = await apiClient.get<{ client?: BEClientConfig }>(
        `/client-registration-config/${encodeURIComponent(id)}`,
        {
          validateStatus: (status) => status === 200 || status === 404,
        }
      )
      client = data?.client
    } catch (err) {
      return Promise.reject({
        message: SignupMachineError.InternalError,
      })
    }
    if (!client) {
      return undefined
    }
    const { additionalEligibility, ...clientProperties } = client
    return {
      ...clientProperties,
      enabled: true,
      gbEthnicity: additionalEligibility.ethnicity ?? false,
      requireSouthAfricaIDNumber:
        additionalEligibility.requireSouthAfricaIDNumber ?? false,
    }
  },
}

export default Object.freeze(companyService)
export type { Company }
