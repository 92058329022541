import {
  TrackPersonifyRedirectDecision,
  logMessage,
  trackPersonifyRedirect,
  trackPersonifyRedirectDecision,
} from '~services/analyticsService/analytics.service'

import { SignupContext } from '../types/signupContext'

export enum PersonifyQueryParams {
  Authenticated = 'authenticated',
  Error = 'error',
  ErrorDescription = 'error_description',
}

export class PersonifyService {
  private static companyId = 140

  public static shouldRedirectToPersonify(context: SignupContext): boolean {
    const urlParams = new URLSearchParams(window.location.search)
    const isAuthenticated =
      urlParams.get(PersonifyQueryParams.Authenticated) === 'true'
    const error = urlParams.get(PersonifyQueryParams.Error)
    const errorDescription = urlParams.get(
      PersonifyQueryParams.ErrorDescription
    )

    const trackingAttributes: Partial<
      TrackPersonifyRedirectDecision['attributes']
    > = {
      error,
      errorDescription,
      authenticated: isAuthenticated,
    }

    logMessage('PersonifyService.shouldRedirectToPersonify', {
      ...trackingAttributes,
      companyId: context.company.companyId,
      featureSwitch: import.meta.env.VITE_ENABLE_PERSONIFY_FLOW,
    })

    if (this.isPersonify(context.company.companyId)) {
      if (error || errorDescription) {
        trackPersonifyRedirectDecision({
          ...trackingAttributes,
          isPersonify: true,
          redirect: false,
        })
        return false
      } else if (isAuthenticated) {
        trackPersonifyRedirectDecision({
          ...trackingAttributes,
          isPersonify: true,
          redirect: false,
        })
        return false
      } else if (import.meta.env.VITE_ENABLE_PERSONIFY_FLOW === 'true') {
        trackPersonifyRedirectDecision({
          ...trackingAttributes,
          isPersonify: true,
          redirect: true,
        })
        return true
      } else {
        trackPersonifyRedirectDecision({
          ...trackingAttributes,
          isPersonify: true,
          redirect: false,
        })
        return false
      }
    } else {
      trackPersonifyRedirectDecision({
        ...trackingAttributes,
        isPersonify: false,
        redirect: false,
      })
      return false
    }
  }

  public static isPersonify(
    companyId: SignupContext['company']['companyId']
  ): boolean {
    const isPersonify = companyId === this.companyId

    logMessage('PersonifyService.isPersonify', {
      companyId: companyId,
      isPersonify,
    })

    return isPersonify
  }

  public static redirectToPersonify() {
    const authUrl = this.getAuthUrl()

    logMessage('PersonifyService.redirectToPersonify', {
      authUrl,
    })

    let timeout = 0
    try {
      const envTimeout = parseInt(
        import.meta.env.VITE_PERSONIFY_REDIRECT_DELAY,
        10
      )
      if (!isNaN(envTimeout) && !!envTimeout) {
        timeout = envTimeout
      }
      // eslint-disable-next-line no-empty
    } catch {}

    setTimeout(() => {
      trackPersonifyRedirect()
      window.location.href = authUrl
    }, timeout)
  }

  public static getAuthUrl(): string {
    const redirectUrl = this.getPelagoRedirectUrl()
    const params = new URLSearchParams({
      client_id: import.meta.env.VITE_PERSONIFY_CLIENT_ID,
      client_secret: import.meta.env.VITE_PERSONIFY_CLIENT_SECRET,
      scope: 'events offline_access',
      response_type: 'code',
      redirect_uri: redirectUrl,
    })

    const authUrl = `${import.meta.env.VITE_PERSONIFY_AUTH_URL}?${params.toString()}`

    logMessage('PersonifyService.getAuthUrl', {
      authUrl,
    })

    return authUrl
  }

  private static getPelagoRedirectUrl(): string {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(PersonifyQueryParams.Authenticated, 'true')
    searchParams.set('client_id', this.companyId.toString())
    const redirectUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`

    logMessage('PersonifyService.getPelagoRedirectUrl', {
      redirectUrl,
    })

    return redirectUrl
  }
}
