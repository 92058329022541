import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import ProgressBar from '~components/ProgressBar'
import Logo from '~images/logo.svg'
import { StateType } from '~utils/machineTypes'

import './Header.scss'

interface HeaderProps {
  state: StateType
}

/**
 * Header
 *
 * Global header component
 */
export default function Header({ state }: HeaderProps): ReactElement {
  const progress = getProgressValue(state)
  const { t } = useTranslation()

  return (
    <>
      <header className="header">
        <div className="header__container">
          <div className="header__left">
            <img
              alt={t('common:global.companyName')}
              src={Logo}
              className="header__logo"
              height="27"
            />
          </div>
          <div className="header__right">
            <ProgressBar
              variant="determinate"
              value={progress}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </header>
    </>
  )
}
/**
 * getProgressValue
 *
 * Returns the length for the progress bar by manually assigning a value to each state
 *
 * TODO: [GROW-589] investigate how to programmatically assign progress value
 *
 * @param state
 * @returns number - progress value between 0-100
 */

function getProgressValue(state: StateType): number {
  let progress = 0
  if (
    state.hasTag('eligibilityStep') ||
    state.hasTag('lighterAddictionSelector') ||
    state.hasTag('login') ||
    state.hasTag('resetPassword')
  ) {
    progress = 12.5
  }
  if (
    state.hasTag('companySelector') ||
    state.hasTag('esiCarrierSelector') ||
    state.hasTag('lighterEligibilityName') ||
    state.hasTag('loginWithPassword')
  ) {
    progress = 25
  }
  if (
    state.hasTag('accessCode') ||
    state.hasTag('lighterAccountCreation') ||
    state.hasTag('lighterOTPAccountCreation') ||
    state.hasTag('resetPasswordEmailSent')
  ) {
    progress = 37.5
  }
  if (state.hasTag('otpCode')) {
    progress = 40
  }
  if (state.hasTag('insurancePlanDetails')) {
    progress = 42.5
  }
  if (
    state.hasTag('addictionSelector') ||
    state.hasTag('lighterEligibilityAddress')
  ) {
    progress = 50
  }
  if (state.hasTag('signUpStep2') || state.hasTag('lighterEligibilityBirth')) {
    progress = 62.5
  }
  if (
    state.hasTag('lighterPhoneNumber') ||
    state.hasTag('lighterEmailAddress') ||
    state.hasTag('resetPasswordNewOne') ||
    state.hasTag('resetPasswordCodeExpired')
  ) {
    progress = 75
  }
  if (state.hasTag('callChoiceDecision') || state.hasTag('hdhpNotice')) {
    progress = 84
  }
  if (
    state.hasTag('internalError') ||
    state.hasTag('lighterStateUnavailable')
  ) {
    progress = 87.5
  }
  if (state.hasTag('bookCall')) {
    progress = 87.5
  }
  if (state.hasTag('careCoordinatorCall')) {
    progress = 87.5
  }
  if (state.hasTag('beInTouch')) {
    progress = 87.5
  }
  if (
    state.hasTag('downloadTheApp') ||
    state.hasTag('successfullyRegistered') ||
    state.hasTag('resetPasswordCompleted')
  ) {
    progress = 100
  }
  return progress
}
