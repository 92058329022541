import { CircularProgress, Link } from '@mui/material'
import { getFixedT } from 'i18next'
import { ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'

import { PersonifyService } from '~services/signupMachine/services/personifyService'
import { StateType } from '~utils/machineTypes'

import './LoadingOverlay.scss'

interface LoadingOverlayProps {
  state: StateType
}

const LOADING_OVERLAY_TITLE_ID = 'loading-overlay__container__title'

export default function LoadingOverlay({
  state,
}: LoadingOverlayProps): ReactElement {
  const tLoading = getFixedT(null, UniversalI18nNamespace.Common, 'loading')
  let title = tLoading('loading')
  let paragraph: ReactElement | string = tLoading('pleaseDoNot')
  if (
    state.hasTag('signUpStep2') ||
    state.hasTag('lighterGeneralAccountCreation')
  ) {
    title = tLoading('creatingYour')
  } else if (state.hasTag('lighterGeneralContact')) {
    title = tLoading('updatingYour')
  } else if (state.hasTag('fetchingProgress')) {
    title = tLoading('fetchingYourProgress')
  } else if (state.hasTag('personifyRedirect')) {
    title = tLoading('personifyRedirect.title')
    paragraph = (
      <Trans
        i18nKey="common:loading.personifyRedirect.paragraph"
        components={{
          personifyLink: (
            <Link
              href={PersonifyService.getAuthUrl()}
              className="pel-link-button-underline"
            />
          ),
        }}
      />
    )
  }

  return (
    <div
      className="loading-overlay"
      role="dialog"
      aria-live="polite"
      aria-modal="true"
      aria-labelledby={LOADING_OVERLAY_TITLE_ID}
    >
      <div className="loading-overlay__container">
        <CircularProgress
          size="46px"
          color="primary"
          aria-label={tLoading('loading')}
        />
        <h2
          className="loading-overlay__container__title"
          id={LOADING_OVERLAY_TITLE_ID}
        >
          {title}
        </h2>
        <p className="loading-overlay__container__message">{paragraph}</p>
      </div>
    </div>
  )
}
